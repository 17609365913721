import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from "../components/layout"
import TermsJS from "../../public/tos.js"

const TermsPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            privacy {
              title
              description
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.pages.privacy.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={data.site.siteMetadata.pages.privacy.title}/>
          <meta name="description" content={data.site.siteMetadata.pages.privacy.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.pages.privacy.url} />
          
      </Helmet>
      <Layout>
        <h1 className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl">Terms of Service</h1>
        
        <a href="https://www.iubenda.com/terms-and-conditions/44288070" class="iubenda-white iubenda-embed" title="Terms and Conditions ">Click here to consult our Terms and Conditions</a>
        <script src={TermsJS}></script>
        
      </Layout>
    </>
  )
}

export default TermsPage
